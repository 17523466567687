import React from 'react';

// Styles
import {Content} from "../styles";

const EmptyLayout = ({children}) => {
    return (
        <Content>
            {children}
        </Content>
    )
};

export default EmptyLayout;
