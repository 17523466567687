import React from 'react';
import { ThemeProvider } from 'styled-components';

import { primaryTheme } from '../theme';

const withPrimaryTheme = ComposedComponent =>
    class extends React.Component {
        render() {
            return (
                <ThemeProvider theme={primaryTheme}>
                    <ComposedComponent {...this.props} />
                </ThemeProvider>
            );
        }
    };

export default withPrimaryTheme;
