import React, { useCallback, useState, useEffect } from 'react';
import styled, { withTheme, css } from 'styled-components';
import useMedia from 'react-use/lib/useMedia';
import posed, { PoseGroup } from 'react-pose';
import { media } from '../theme';

// Styles
import { Flex, Section } from '../styles';

// Components
import { Layout, Card, Background, Button } from '../components';
import SidebarContainer from '../sections/SidebarContainer';
import { EmptyLayout } from '../layouts';
import withPrimaryTheme from '../components/withPrimaryTheme';

import { mobile } from '../utils';

// Images
import clock from '../images/clock.svg';

const ComingSoon = ({ theme }) => {
    const isPhone = useMedia(`(max-width: ${theme.sizes.phone}px)`);
    const pick = useCallback((a, b) => (isPhone ? a : b), [isPhone]);
    const [visible, setVisible] = useState(false);
    useEffect(() => setVisible(true), []);

    return (
        <Layout>
            <SidebarContainer />
            <EmptyLayout>
                <Section height="100vh">
                    <Background
                        color={theme.secondaryColor}
                        padding={{phone: '0 19px', def: '0'}}
                    >
                        <Flex alignItems="center">
                            <PoseGroup>
                                {visible && (
                                    <Enter key="enter">
                                        <Card
                                            
                                            width={pick('100%', '868px')}
                                            spacing={spacing}
                                            margin={{phone: '0',  def: '0 0 0 -22px'}}
                                        >
                                            <img src={clock} alt="clock" />
                                            <Title>Coming Soon...</Title>
                                            <Description>
                                                AgePass is launching in 2019
                                            </Description>
                                            <Button width={'150px'} link="/" >
                                                {' '}
                                                Back{' '}
                                            </Button>
                                        </Card>
                                    </Enter>
                                )}
                            </PoseGroup>
                        </Flex>
                    </Background>
                </Section>
            </EmptyLayout>
        </Layout>
    );
};

export default withPrimaryTheme(withTheme(ComingSoon));

const Enter = posed.div({
    enter: { x: 0, opacity: 1, width: '100%' },
    exit: { x: 50, opacity: 0, width: '100%' },
});

const Title = styled.h2`
    font-size: 64px;
    color: ${props => props.theme.primaryColor};
    margin: 30px 0;

    ${media.laptop`
        font-size: 42px;
    `}

    ${media.phone`
        font-size: 42px;
    `}
`;

const Description = styled.div`
    color: ${props => props.theme.primaryColor};
    font-size: 28px;
    margin: 30px 0 65px 0;

    ${media.laptop`
        font-size: 20px;
    `}

    ${media.phone`
        font-size: 20px;
    `}
`;

const spacing = css`
    padding: 75px 0 65px 50px;

    ${media.laptop`
        padding: 60px 30px 160px 30px;
    `}
    
    ${media.phone`
        padding: 60px 30px 160px 30px;
    `}
`;
